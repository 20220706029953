<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">预警人员管理</span>
            <div class="a-flex-rcc">
                <!-- <el-button icon="el-icon-upload2" class="s-btn-exp" @click="exportfile">导出</el-button> -->
                <el-button icon="el-icon-plus" type="primary" class="s-btn-add" @click="addUserGroup">添加群组</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-pagview ref="financeCompany" @setData="setTableData" :pagerCount="5" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.financeCompanyList">
                <el-table 
                    :border="true"
                    ref="financeCompanyList" 
                    :data="tableData" 
                    :span-method='mergeCols'
                    :highlight-current-row="true" 
                    @filter-change='filterFun' 
                    v-sticky="{ top: 0, parent:'.el-card__body' }" 
                    style="width: 100%">
                    <el-table-column prop="companyName" label="群组名称" min-width="200">
                        <template slot-scope="{ row }">
                            <span>{{ row.groupName?row.groupName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="timeAllAmount" label="群组范围" min-width="200">
                        <template slot-scope="{ row }">
                            <div class="groupUsersBox" v-if="row.isGlobal">
                                <span>全局</span>
                            </div>
                            <div class="groupUsersBox" v-else-if="row.isNational">
                                <span>全国</span>
                            </div>
                            <div class="groupUsersBox" v-else>
                                <span v-if="row.regionName">{{ row.regionName }}</span>
                                <span v-if="row.companyName">商户：{{ row.companyName }}</span>
                                <span v-if="row.incomeName">项目：{{ row.incomeName }}</span>
                                <span v-if="row.stationName">站点：{{ row.stationName }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="timeAllAmount" label="群组成员" min-width="200">
                        <template slot-scope="{ row }">
                            <div class="a-flex-rfsc a-flex-wrap">
                                <span class="stationTag" v-for="(item,index) in row.groupUsers" :key="index">
                                    {{ item.userName }} {{ item.mobile }}（{{ item.companyName }}）
                                </span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="companyName" label="通知范围" min-width="100">
                        <template slot-scope="{ row }">
                            <span>{{ row.isGlobal?'全局':'区域' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="操作" width="75" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                                <img src="../../assets/icon/option-edit.png" class="a-wh-16" @click="addUserGroup(scope.row)" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
        <send-user ref="sendUser"></send-user>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import util from '../../../src/utils/util'
    import sendUser from './child/sendUser.vue';
    import {
		CodeToText,
	} from 'element-china-area-data'
    export default {
        components: { sendUser },
        data () {
            return {
                tableData: [],
                cosTableData: [],
                util: util,
                pageParam: {
                    url: this.$Config.apiUrl.warningUserGroupPage,
                    method: "post",
                    params: {
                    },
                    freshCtrl: 1,
                },
                choiceDate: '',
                pickerOptions: {
                    onPick: ({ maxDate, minDate }) => {
                        this.choiceDate = minDate.getTime()
                        if (maxDate) this.choiceDate = ''
                    },
                    disabledDate: (time) => {
                        if (this.choiceDate) {
                            const one = 92 * 24 * 3600 * 1000
                            const minTime = this.choiceDate - one
                            const maxTime = this.choiceDate + one
                            return time.getTime() < minTime || time.getTime() > maxTime
                        }
                    },
                },
            }
        },
        created () {
        },
        activated () {
            this.pageParam.freshCtrl++;
        },
        computed:{
            ...mapState({
                company: state => state.company.company,
            }),
        },
        methods: {
            setTableData(data) {
                let arr = []
                // 遍历处理后台返回数据
                data.map((item,index)=>{
                    item.groupInfo.map((ite,idx)=>{
                        let obj = {
                            ...ite,
                            groupId: item.groupId,
                            groupName: item.groupName,
                            regionName: (ite.provinceCode?CodeToText[ite.provinceCode]:"") + 
                                (ite.cityCode ? '/' + CodeToText[ite.cityCode] : '') +  
                                (ite.areaCode ? '/' + CodeToText[ite.areaCode] : ''),
                        }
                        arr.push(obj)
                    })
                })
                this.tableData = arr
                this.getCosTableData()
            },
            handlerRest() {
                // this.pageParam.params = {
                //     companyId: this.company.id,
                //     endDate: this.$getDay.getTodayBeforeDays(0) + ' 23:59:59',
                //     startDate: this.$getDay.getTodayBeforeDays(30) + ' 00:00:00',
                // };
                // this.handlerSearch()
            },
            handlerSearch() {
                if(this.$getDay.enumerateDaysBetweenDates(this.pageParam.params.startDate,this.pageParam.params.endDate).length > 31){
                    this.$message.error('最大查询范围31天')
                    return
                }
                this.$refs['financeCompany'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            // 添加用户群组
            addUserGroup (datas) {
                this.$router.push({
                    path:'/warning/warning-user-edit',
                    query: {
                        groupId: datas?datas.groupId:''
                    }
                })
            },
            showSendUser (datas) {
                this.$refs['sendUser'].drawerVisible = true
            },
            //条件筛选
            filterFun(value){
                // for(var key in value){
                //     if(key=='incomeStatus'){
                //         if(value[key].length==0){
                //             this.pageParam.params.incomeStatus = ''
                //         }else{
                //             this.pageParam.params.incomeStatus = value[key][0]
                //         }
                //     }
                //     if(key=='incomeType'){
                //         if(value[key].length==0){
                //             this.pageParam.params.incomeType = ''
                //         }else{
                //             this.pageParam.params.incomeType = value[key][0]
                //         }
                //     }
                // }
                // this.$refs['financeSYRZ'].pageNum = 1
                // this.pageParam.freshCtrl++;
            },

            // 合并行
            mergeCols ({ row, column, rowIndex, columnIndex }) {
                if(columnIndex === 0 || columnIndex === 3 || columnIndex === 4){
                    for (let index = 0; index < this.cosTableData.length; index++) {
                        const element = this.cosTableData[index];
                        for (let c_index = 0; c_index < element.length; c_index++) {
                            if(rowIndex == element[c_index]){
                                if(c_index == 0){
                                    return {
                                        rowspan: element.length,
                                        colspan: 1
                                    }
                                }else{
                                    return {
                                        rowspan: 0,
                                        colspan: 0
                                    }
                                }   
                            }
                        }
                    }
                }
            },
            // 获取需要合并单元格的数据
            getCosTableData () {
                this.cosTableData = []
                let obj = {}
                // 将相同编号的数据储存到同一个数组中，并以该编号为key值添加到obj对象中 以便之后取用
                this.tableData.map((item,index)=>{
                    if(obj[item.groupId]){
                        obj[item.groupId].push(index)
                    }else{
                        obj[item.groupId] = []
                        obj[item.groupId].push(index)
                    }
                })
                // 将obj对象中数组长度大于1（即存在多个相同编号）的数据push到this.cosTableData中
                for (const key in obj) {
                    if(obj[key].length > 1)
                    this.cosTableData.push(obj[key])
                }
            },

            exportfile () {
                let startDate = this.pageParam.params.startDate || this.$getDay.getTodayBeforeDays(92) + ' 00:00:00'
                let endDate = this.pageParam.params.endDate || this.$getDay.getTodayBeforeDays(0) + ' 00:00:00'
                this.$exfile({
                    code: 12,
                    fileName: startDate.split(' ')[0] + ' 至 ' + endDate.split(' ')[0] + ' 商户对账表',
                    startTime: startDate.split(' ')[0],
                    endTime: endDate.split(' ')[0],
                    params: {
                        ...this.pageParam.params,
                        startDate: startDate,
                        endDate: endDate
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .groupUsersBox{
        display: flex;
        flex-direction: column !important;
        justify-content: flex-start !important;
        align-items: flex-start !important;
        width: 100%;
        height: 100%;
    }
    .stationTag{
        padding: 0px 6px;
        background: #f4f4f5;
        color: #909399;
        border: 1px solid #e9e9eb;
        border-radius: 3px;
        margin-right: 4px;
        margin-bottom: 4px;
        font-size: 12px;
        // word-break: keep-all;
    }
    .s-btn-add{
        width: 105px;
    }
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 0 !important;
		height: 0 !important;
	}
	/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
		height: 100% !important;
	}
</style>