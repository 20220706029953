<template>
    <div>
        <el-drawer
            title="群组成员"
            :size="280"
            :visible.sync="drawerVisible"
            ref="drawer"
            direction="rtl">
            <div class="content">
                <div class="content-layout a-flex-1">
                    <div class="city-item" v-for="(item,index) in cityData" :key="index">
                        <div class="a-flex-rsbfs city-item-title">
                            <span class="">{{ item.regionName }}</span>
                        </div>
                        <div class="city-item-content">
                            <div class="a-flex-rfsc" v-for="(ite,idx) in item.userList" :key="idx">
                                <span class="a-flex-1">{{ ite.userName }}&nbsp;&nbsp;&nbsp;&nbsp;{{ ite.phone }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="a-flex-rsbc">
                    <el-button class="a-flex-1" @click="$refs.drawer.closeDrawer()">关闭</el-button>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                drawerVisible: false,
                cityData: [{
                    provinceCode: 99,
                    cityCode: '',
                    areaCode: '',
                    provinceName: '',
                    cityName: '',
                    areaName: '',
                    regionName: '全国',
                    phone: '',
                    userList: [{
                        userName: '陈瑜',
                        phone: '15708415426'
                    }]
                },{
                    provinceCode: 99,
                    cityCode: '',
                    areaCode: '',
                    provinceName: '',
                    cityName: '',
                    areaName: '',
                    regionName: '四川/成都',
                    phone: '',
                    userList: []
                },{
                    provinceCode: 99,
                    cityCode: '',
                    areaCode: '',
                    provinceName: '',
                    cityName: '',
                    areaName: '',
                    regionName: '广东/广州',
                    phone: '',
                    userList: []
                },],
            };
        },
        methods:{

        }
    }
</script>

<style lang="scss" scoped>
    .content{
        padding: 0 24px 24px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        height: 100%;
        .content-layout{
            overflow-y: scroll;
            margin-bottom: 24px;
        }
        .content-layout::-webkit-scrollbar {
            /*width: 0;宽度为0隐藏*/
            width: 0 !important;
            height: 0 !important;
        }
        .city-item{
            width: 220px;
            margin: 0px 0px 15px;
            border-radius: 4px;
            border: 1px solid #E4E7ED;
        }
        .city-item-title{
            background: #409EFF;
            color: #ffffff;
            font-size: 12px;
            padding: 6px 10px;
            border-radius: 4px 4px 0 0;
            line-height: 18px;
        }
        .city-item-content{
            padding: 10px;
        }
    }
</style>